import React from 'react';
import {Link} from 'gatsby'

import VectorShape11 from '../WeServe/vector-shape11.png'

const Raionebi = () => {
    return (
        <section className="about-area ptb-100">

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}

        <div className="container">
            <div className="about-inner-area">
            <h1>რაიონები</h1>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="about-text">
                            
                            {/* <p>Real innovations.</p> */}
                            
                            <ul className="features-list">
                            <li><i className="flaticon-right"> </i>
                                    <Link to="/თბილისი" className="learn-more-btn">
                                        თბილისი
                                    </Link>
                                </li>
                                {/* <li><i className="flaticon-right"> </i>
                                    <Link to="/vake" className="learn-more-btn">
                                        ვაკე
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/saburtalo" className="learn-more-btn">
                                        საბურთალო
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/mtawminda" className="learn-more-btn">
                                        მთაწმინდა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/gldani" className="learn-more-btn">
                                        გლდანი-ნაძალადევი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/didube" className="learn-more-btn">
                                        დიდუბე
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/didi-dighomi" className="learn-more-btn">
                                        დიდი დიღომი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/krwanisi" className="learn-more-btn">
                                        კრწანისი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/isani-samgori" className="learn-more-btn">
                                        ისანი-სამგორი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/chugureti" className="learn-more-btn">
                                        ჩუღურეთი
                                    </Link>
                                </li> */}

                            </ul>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="about-text">
                            {/* <h3>Our Mission</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბათუმი" className="learn-more-btn">
                                        ბათუმი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ქუთაისი" className="learn-more-btn">
                                        ქუთაისი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ზუგდიდი" className="learn-more-btn">
                                        ზუგდიდი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მცხეთა" className="learn-more-btn">
                                        მცხეთა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/თელავი" className="learn-more-btn">
                                        თელავი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/რუსთავი" className="learn-more-btn">
                                        რუსთავი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ფოთი" className="learn-more-btn">
                                        ფოთი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ოზურგეთი" className="learn-more-btn">
                                        ოზურგეთი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მესტია" className="learn-more-btn">
                                        მესტია
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                            {/* <h3>Who we are</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/წყალტუბო" className="learn-more-btn">
                                        წყალტუბო
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სამტრედია" className="learn-more-btn">
                                        სამტრედია
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ქობულეთი" className="learn-more-btn">
                                        ქობულეთი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/zestafoni" className="learn-more-btn">
                                        ზესტაფონი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გურჯაანი" className="learn-more-btn">
                                        გურჯაანი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ახალციხე" className="learn-more-btn">
                                        ახალციხე
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გორი" className="learn-more-btn">
                                        გორი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გარდაბანი" className="learn-more-btn">
                                        გარდაბანი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ხაშური" className="learn-more-btn">
                                        ხაშური
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}
    </section>
    )
}

export default Raionebi;